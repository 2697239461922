import { Layout, } from 'antd';
// import Image from 'next/image';

// import { RightOutlined } from '@ant-design/icons'
// import MyHeader from '../components/header';
// import Subheader from '../components/subheader';
import { appWithTranslation, useTranslation } from 'next-i18next'
// import initAuth from '../../initAuth' 

// // import { Provider } from 'react-redux'
// import { useStore } from '../store'

// import { ThemeProvider,Container } from '@mui/material';
// import CssBaseline from '@mui/material/CssBaseline';
import Script from 'next/script'

// import { purple, green } from '@mui/material/colors';
// import green from '@mui/material/colors/green';
import { ConfigProvider } from 'antd';
import { useEffect, useState, useCallback, memo } from 'react'

// import { MeterialHeader, ElevationScroll } from '../components/meterialHeader'
// import { SearchByOptions } from '../components'
import Router from 'next/router';

import { getChangeLanguageData } from '../utils/requests'
import dynamic from 'next/dynamic'
// import { 
//   LinkHits 
// } from '../components'
// import { MeterialHeader, ElevationScroll } from '../ui-components/src/components'
import { useRouter } from 'next/router'
// import NProgress from 'nprogress';
import { config } from '@fortawesome/fontawesome-svg-core'
import get from 'lodash/get'
import '@fortawesome/fontawesome-svg-core/styles.css'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import { GoogleAnalytics } from '@next/third-parties/google'

config.autoAddCss = false

const { Content } = Layout;
// initAuth()

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'antd/dist/antd.less'
import '../styles/globals.css'
import '../styles/mytheme.scss'
// import "../styles/nprogress.css";
import firebase from '../utils/firebaseInit';
// import Link from 'next/link'
// import { initializeApp } from 'firebase/app';

import('leaflet/dist/leaflet.css') // import leaflet css instead of using CDN
import 'leaflet-geosearch/dist/geosearch.css' // need to make location search display correctly
import {
  isDesktop,
  isMobile
} from "react-device-detect";
// const firebaseConfig = {
//   apiKey: "AIzaSyC8Yi6jarHdVaGs9bkwZhfPvQ_do0eZvuE",
//   authDomain: "directed-radius-140921.firebaseapp.com",
//   projectId: "directed-radius-140921",
//   storageBucket: "directed-radius-140921.appspot.com",
//   messagingSenderId: "866569999379",
//   appId: "1:866569999379:web:5fb234f0b59c777c6e1be6"
// };
import { themeAntD } from '../utils/theme';
// import { useReportWebVitals } from 'next/web-vitals'

// import { MemoizedFooter } from '../components';
// // Initialize Firebase
// export const app = initializeApp(firebaseConfig);
// import { LinkHits } from '../components';
// const DynamicSpin = dynamic(() => import('antd/es/spin')
//   .then(module => module.Spin), { ssr: false })

const DynamicMemoizedFooter = dynamic(() => import('../components')
  .then(module => module.MemoizedFooter), { ssr: false })
  
// const DynamicLinkHits = dynamic(() => import('../components')
//   .then(module => module.LinkHits), { ssr: false })
  
const DynamicLayoutAppBar = dynamic(() => import('../components/MainAppBar')
  .then(module => module.MainAppBar), { 
    ssr: false,
    loading: () => <div>Loading AppBar...</div> 
  });

const LeaveYourRequirementModal = dynamic(() => import("../components/LeaveYourRequirement/LeaveYourRequirementModal")
  .then(module => module.LeaveYourRequirementModal), { ssr: false });

const MobileSearchModal = dynamic(() => import("../components/search-by-options/MobileSearch")
  .then(module => module.MobileSearch), { ssr: false });

// const MyFooter = ({ t, locale }) => {
//   return <Footer className="footer">
//     <DynamicLinkHits t={t} locale={locale} />
//     <Row justify="center" gutter={[16,24]} className="container footer-container">
//       <Col xs={24} sm={24} md={24} lg={8} xl={8} key={'footer-logo'}> 
//         <Row>
//           <Image src="/assets/new-logo_s.png" width={160} height={80} alt={'9asset-logo'}/>
//         </Row>
//       </Col>
//       <Col xs={24} sm={24} md={24} lg={8} xl={8} key={'footer-detail'}>
//         <Row>
//           <Col span={12}>
//             <RightOutlined />{t("About us")}
//           </Col>
//           <Col span={12}><RightOutlined /><Link legacyBehavior href="/web-sitemap">{t("Sitemap")}</Link></Col>
//         </Row>
//         <Row>
//           <Col span={12}><RightOutlined /><Link legacyBehavior href="/privacy-policy">{t("Privacy policy")}</Link></Col>
//           <Col span={12}><RightOutlined />{t("Help")}</Col>
//         </Row>
//         <Row>
//           <Col span={12}><RightOutlined /><Link legacyBehavior href="/terms">{t("Term & Condition")}</Link></Col>
//           <Col span={12}><RightOutlined />{t("Rate")}</Col>
//         </Row>
//         <Row>
//           <Col span={12}><RightOutlined />{t("Advertise with us")}</Col>
//           <Col span={12}><RightOutlined />{t("Contact Us")}</Col>
//         </Row>
//       </Col>
//       <Col xs={24} sm={24} md={24} lg={8} xl={8} key={'footer-company'}>
//         <Row>
//           {t("My Address")}
//         </Row>
//         <Row>
//           {t("Tel")}
//         </Row>
//         <Row>
//           {t("Email")}
//         </Row>
//         <Row>
//           {t("Office Hours")}
//         </Row>
//         <Row>
//           <div className='social-icon-container'>
//             <a href='https://www.linkedin.com/in/9asset-com/'>
//               <svg className="svg-social-icon MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" 
//                 focusable="false" aria-hidden="true" viewBox="0 0 24 24" 
//                 data-testid="LinkedInIcon"><path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path></svg>
//             </a>
//             <a href='https://www.facebook.com/9asset/'>
//               <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" className="svg-social-icon fa-facebook-f " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
//             </a>
//             <a href='https://www.youtube.com/@9asset'>
//               <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" className="svg-social-icon fa-youtube " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>
//             </a>
//             <a href='https://twitter.com/9assetC'>
//               <svg className="svg-social-icon " focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="XIcon" tabIndex="-1" title="X"><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></svg>
//             </a>
//           </div>
//         </Row>
//       </Col>
      
//     </Row>
//     <Row className="container copyright"> { `${t("CopyRight")} © 2024 ${t("by")} 9asset.com v${process.env.NEXT_PUBLIC_BUILD_NUMBER || '3.0.0'}` }</Row>
//   </Footer>
// }

// const MemoizedFooter = memo(MyFooter);

const MyApp = (props)  => {
  const { Component, pageProps, showListPathIds } = props;
  // const store = useStore(pageProps.initialReduxState);

  const { t } = useTranslation();
  const router = useRouter();

  const { locale, pathname, asPath, query, basePath  } = router;

  const [ isMobileFilterOpen, setIsMobileFilterOpen ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [isRequirementMenuOpened, setIsRequirementMenuOpened] = useState(false);
  const [pageContext, setPageContext] = useState(null);

  // useReportWebVitals((metric) => {
  //   console.log('metric: ', metric)
  // }) 
  useEffect(() => {
  //   if (locale === '' || locale === 'th') {
  //     debugger;
  //     const saved = localStorage.getItem('i18nextLng');
  //     if (saved && saved !== 'th') {
  //       router.push(
  //         { pathname, query },
  //         asPath,
  //         { locale: saved }
  //       )
  //     } else {
  //       localStorage.setItem('i18nextLng', 'th');
  //     }
  //   } else {
    localStorage.setItem('i18nextLng', (locale||'th').toLowerCase());
  //   }
  }, []);

  // NProgress.start();
  useEffect(() => {
    Router.events.on('routeChangeStart', (url) => {
      console.log('router start...')
      // NProgress.start();
      setLoading(true)
    })
  
    Router.events.on('routeChangeComplete', (url) => {
      console.log('router end')
      // NProgress.done();
      // NProgress.remove();
      setLoading(false)
    })
    // const ads = document.getElementsByClassName("adsbygoogle").length;
    // for (var i = 0; i < ads; i++) {
    //   try {
    //     //@ts-ignore
    //     (window.adsbygoogle = window.adsbygoogle || []).push({});
    //   } catch (e) { }
    // }
  }, [Router])

  const onAppChanged = (e) => {
    const value = e.target.value;
    console.log(value);
    if(value === 'buyer') {
      document.location.href = process.env.NEXT_PUBLIC_BUYER_URL;
    } else if(value === 'seller') {
      document.location.href = process.env.NEXT_PUBLIC_SELLER_URL;
    }
  }

  const handleLogin = () => {
    console.log('handleLogin')
    document.location.href = process.env.NEXT_PUBLIC_LOGIN_URL;
  }

  const prepareBranchName = (branch) => {
    if (!branch) return branch;

    const regex = /ซอย/g
    return branch.replace(regex, '').trim();
  }

  const checkIsProvince = useCallback((pathIds) => {
    if(pathIds.province_id === null && pathIds.location_type_id === null) {
      return true;
    }

    return pathIds ? pathIds.province_id !== null : true;
  }, [])

  const onLangChanged = async (lang) => {
    localStorage.setItem('i18nextLng', lang.toLowerCase());
    console.log('xxxx: ', lang, basePath, asPath);
    const paths = get(query, 'action');
    console.log('actions: ', paths);

    let url = '/';

    if(asPath === '/privacy-policy') {
      if(lang.toLowerCase() === 'en') {
        router.push({ pathname, query }, asPath, { locale: lang })
      } else {
        router.push({ pathname, query }, asPath, { locale: lang })
      }
    }

    // debugger;
    if (asPath.startsWith('/article')) {
      router.push({ pathname, query }, asPath, { locale: lang });
      // window.location.href = `/${lang === 'th' ? '': `${lang}/`}${url}`;
      return;
    }

    if(paths){
      let params = {};
      const id = parseInt(paths[2]);
      if(!Number.isNaN(id)){
        // property detail page
        let [
          action,
          category = undefined,
          location = undefined,
          // property_name = undefined,
        ] = paths;
  
        params = {
          action,
          category,
          location,
          // property_name,
        }
  
      } else {
        const isProvince = checkIsProvince(props.pageProps.showListPathIds);
        const { project_id } = props.pageProps.showListPathIds;
        if(project_id) {
          // search by project name
          let [
            action,
            category = undefined,
            locationtype = undefined,
            location = undefined,
            location_branch = undefined
          ] = paths;
      
          params = {
            action,
            category,
            locationtype,
            location
          }
          console.log('xxxx: ', params);
        } else if(isProvince){
          // url is in province
          let [
            action,
            category = undefined,
            location = undefined,
            location_branch = undefined
          ] = paths;
      
          location_branch = prepareBranchName(location_branch);
      
          params = {
            action,
            category,
            location,
            location_branch,
            isProvince
          }
        } else {
          // location
          let [
            action,
            category = undefined,
            locationtype = undefined,
            location = undefined,
            location_branch = undefined
          ] = paths;
      
          location_branch = prepareBranchName(location_branch);
      
          params = {
            action,
            category,
            locationtype,
            location,
            location_branch
          }
        }
      }

      const data = await getChangeLanguageData(lang, params);
      console.log("🚀 ~ file: _app.js:212 ~ onLangChanged ~ data", data)
      const queryPath = Object.keys(query).map(key => {
        if(key !== 'action'){ 
          return `${key}=${query[key]}`
        }
      }).join('&');
      if(!Number.isNaN(id)) {
        let value = Object.keys(data.data).map(key => `${data.data[key]}`);
        url = `/${value.join('/')}${queryPath ? `?${queryPath}` : ''}`
      } else {
        url = `/${Object.keys(data.data).map(key => `${data.data[key]}`).join('/')}${queryPath ? `?${queryPath}` : ''}`;
      }
      console.log("🚀 ~ file: _app.js:219 ~ onLangChanged ~ url", url)
    }
    // i18n.changeLanguage(lang);
    // const lang = e.target.value;
    if(lang.toLowerCase() === 'en') {
      // router.push(url, undefined, { locale: 'en', shallow: false})
      window.location.href = `/en${url}`;
    } else if (lang.toLowerCase() === 'cn') {
      // router.push(url, undefined, { locale: 'cn', shallow: false})
      window.location.href = `/cn${url}`;
    } else {
      // router.push(url, undefined, { locale: 'th', shallow: false})
      window.location.href = `${url}`;
    }
  }

  const onMobileSearchClose = (e) => {
    console.log('onMobileSearchClose')
    setIsMobileFilterOpen(false);
  }

  const onMobileSearchClick = (e) => {
    console.log('onMobileSearchClick')
    setIsMobileFilterOpen(true);
  }

  const onMobileSearchOk = (e) => {
    console.log('onMobileSearchOk: ', e);
    setLoading(true)
    setIsMobileFilterOpen(false); 
  }

  const onMenuClick = (type) => {
    if (type === 'article') {
      router.push('/article')
    } else {
      router.push(`/${t(`link.${type}`).replace('/', '')}/${t('estate')}?endPrice=1000000000`)
    }
  }

  const handleProfileMenuItemClick = (item) => {
    if(item.link) {
      window.location.href = `${window.location.origin}${item.link}`;
    }
  }

  const onSubMenuItemClick = (item) => {
    console.log('onSubMenuItemClick: ', item);
    router.push(`${item.link}?endPrice=1000000000`)
  }

  const onMenuBarItemClick = (item) => {
    console.log('onMenuHeaderClick: ', item.link);
    router.push(item.link);
  }

  const onRequirementClicked = () => {
    setIsRequirementMenuOpened(true);
  }

  return (
    <>
        <Script strategy="lazyOnload" async src="https://www.googletagmanager.com/gtag/js?id=G-ZE5MDXDKM8"></Script>
        <Script 
          id='google-analytics'
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-ZE5MDXDKM8', {
                page_path: window.location.pathname,
              });
            `,
            }}
        />
      {
        isDesktop ? undefined:
          <LeaveYourRequirementModal
            open={isRequirementMenuOpened}
            logo="/assets/single-logo.png"
            onClose={() => setIsRequirementMenuOpened(false)}
          />
      }

      {
        isDesktop ?
          <Backdrop
          sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        : undefined
      }
      {/* <DynamicSpin tip="Loading..." spinning={loading}  size="large" style={{top: '0px'}}
        // style={{ zIndex: 1002 }}
        fullscreen
      /> */}
      <DynamicLayoutAppBar
        logoPath="/assets/single-logo.webp"
        app={firebase.app}
        auth={firebase.auth}
        context={pageContext}
        onLanguageChanged={onLangChanged}
        onMenuClicked={onMenuClick}
        onMobileSearchClicked={onMobileSearchClick}
        onRequirementClicked={onRequirementClicked}
      />
      { 
        isMobile ? 
        <MobileSearchModal
          t={t}
          open={isMobileFilterOpen} 
          onMobileSearchClosed={onMobileSearchClose} 
          onMobileSearchClicked={onMobileSearchOk}
        /> : undefined
      }
      <Content className="page-content" >
        <ConfigProvider theme={themeAntD}>
          <Component
            {...pageProps}
            // isMobileFilterOpen={isMobileFilterOpen} 
            // onMobileSearchClose={onMobileSearchClose} 
            // onMobileSearchOk={onMobileSearchOk}
            setPageContext={setPageContext}
          />
        </ConfigProvider>
      {/* <DynamicLinkHits t={t} locale={locale} /> */}
      </Content>
      <DynamicMemoizedFooter t={t} locale={locale} />
    </>
  )
}

// const App = ({ Component, pageProps }) => (
//   <ConfigProvider theme={theme}>
//     <Component {...pageProps} />
//   </ConfigProvider>
// );


export default appWithTranslation(MyApp)
